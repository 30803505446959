import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import search from "../assets/search.svg";
import profile from "../assets/profile.svg";
import heart from "../assets/heart.svg";
import music from "../assets/music.svg";
import beats from "../assets/beats.svg";
import paint from "../assets/paint.svg";
import market from "../assets/market.svg";
import brand from "../assets/brand.svg";
import people from "../assets/people.svg";
import logoutimg from "../assets/logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/authSlice";
import toast from "react-hot-toast";
import { clearUser } from "../redux/userSlice";
import viplogo from "../assets/viplogo.png";

const navLinks = [
  {
    src: profile,
    label: "My Account",
    specialGroup: true,
    link: "/user-dashboard",
  },
  { src: heart, label: "Favourite", specialGroup: true, link: "/favourite" },
  { src: music, label: "VIP Catalog", link: "/vip" },
  // { src: music, label: 'Beats' },
  { src: beats, label: "Mixing & Mastering", link: "/mixing-&-mastering" },
  { src: paint, label: "Artworks", link: "/artworks" },
  { src: market, label: "Marketing", link: "/marketing" },
  { src: brand, label: "Branding", link: "/branding" },
  { src: people, label: "About", link: "/about" },
];

const Sidebar = ({ toggleSidebar, sidebarOpen }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const HandleLogout = async () => {
    await dispatch(logout());
    // dispatch(clearUser())
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    toast.success("Logged out Successfully");
  };

  return (
    <div
      className={`bg-[#141414] fixed md:relative top-0 left-0 right-0 min-h-full px-5 text-center text-white z-[99999] transition-transform duration-300 ${
        sidebarOpen ? "translate-y-0" : "-translate-y-full md:translate-y-0"
      }`}
    >
      <div className="py-6">
        <Link to="/" className="items-center">
          <img src={viplogo} alt="VIP Logo" className="mx-auto w-40 md:w-56" />
        </Link>
        <button
          className="md:hidden block absolute top-4 right-4"
          onClick={toggleSidebar}
        >
          {sidebarOpen ? (
            <FaTimes className="text-white text-2xl" />
          ) : (
            <FaBars className="text-white text-2xl" />
          )}
        </button>
      </div>
      <div className="flex flex-col px-5 gap-3 overflow-y-auto h-full md:h-auto">
        <div className="flex flex-col gap-5">
          {navLinks
            .filter((link) => link.specialGroup)
            .map((link, index) => (
              <Link
                key={index}
                to={link.link}
                className={`flex gap-2 items-center py-3 px-3 rounded-md cursor-pointer transition-transform duration-300 ${
                  currentPath ===
                  `/${link.label.replace(/ /g, "-").toLowerCase()}`
                    ? "bg-red-500 text-white"
                    : "text-white hover:bg-red-500"
                }`}
                onClick={(e) => {
                  if (!isAuthenticated) {
                    e.preventDefault();
                    navigate("/login");
                  }
                }}
              >
                <img src={link.src} alt={link.label} />
                {link.label}
              </Link>
            ))}
        </div>
        <div className="flex flex-col gap-5">
          {navLinks
            .filter((link) => !link.specialGroup)
            .map((link, index) => (
              <Link
                key={index}
                to={link.link}
                className={`flex gap-2 items-center py-3 px-3 rounded-md cursor-pointer transition-transform duration-300 ${
                  currentPath ===
                  `/${link.label.replace(/ /g, "-").toLowerCase()}`
                    ? "bg-red-500 text-white"
                    : "text-white hover:bg-red-500"
                }`}
              >
                <img src={link.src} alt={link.label} />
                {link.label}
              </Link>
            ))}
        </div>
        <div className="flex items-center py-20 justify-center w-full">
          <button
            className="flex items-center justify-center gap-2 py-3 px-4 w-full bg-white rounded-sm text-red-500 hover:scale-110 transition-transform duration-200"
            onClick={() => {
              HandleLogout();
            }}
          >
            <img src={logoutimg} alt="Logout" />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
