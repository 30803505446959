import React, { useState } from "react";
import google from "../assets/google.svg";
import axios from "axios";
import toast from "react-hot-toast";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { backend_url } from "../libs/data";
import { signup } from "../redux/authSlice";
import { useDispatch } from "react-redux";
import Section from "./Section";
import { useNavigate, useLocation } from "react-router-dom";

const SignUp = () => {
  const [blurName, setBlurName] = useState(false);
  const [blurEmail, setBlurEmail] = useState(false);
  const [blurPassword, setBlurPassword] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const validate = () => {
    const errors = {};
    if (!name) errors.name = "Full Name is required";
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }
    if (!password) {
      errors.password = "Password is required";
    } else if (password.length < 8) {
      errors.password = "Password must be at least 8 characters";
    }
    return errors;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      setLoading(true);
      const res = await axios.post(`${backend_url}/api/signup`, {
        fullName: name,
        email,
        password,
        userType: "freeUser",
      });
      const { token, user } = res.data;
      dispatch(signup({ token, user }));
      localStorage.setItem("email", email);
      toast.success("Signed Up successfully");

      const params = new URLSearchParams(window.location.search);
      const returnUrl = params.get("returnUrl") || "/";
      navigate(returnUrl);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="grid min-h-screen md:grid-cols-2">
          <div className="bg-black md:flex hidden justify-center">
            <div className="text-white flex gap-5 flex-col items-center justify-center">
              <p className="lg:w-[415px] font-normal leading-7 text-center text-[24px]">
                Looking forward working with you! Register and let's make some
                bangers!
              </p>
              <div className="">
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="bg-white py-2 transition-transform duration-300 rounded-md font-medium text-black lg:w-[415px] hover:scale-105"
                >
                  Already have an Account?{" "}
                  <span className="text-red-500">Log in</span>
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <div className="w-full lg:w-3/4 mx-auto lg:px-0 px-7 flex flex-col items-center justify-center ">
              <h1 className="text-4xl font-bold mb-4 text-center">
                Create Free Account
              </h1>
              <form className="space-y-3 w-full" onSubmit={handleSignUp}>
                <div>
                  <label className="block p-1 text-gray-700">
                    Full Name{" "}
                    <span className="text-red-500 font-bold text-xl">*</span>
                  </label>
                  <input
                    onFocus={() => setBlurName(true)}
                    onBlur={() => setBlurName(false)}
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrors({ ...errors, name: "" });
                    }}
                    type="text"
                    className={`w-full px-2 focus:outline-none outline-none py-3 border-2 border-opacity-60  ${
                      blurName
                        ? "border-[#FF3E41] text-[#FF3E41]"
                        : "border-[#D0D0D0]"
                    } rounded-md transition-border duration-500 `}
                    placeholder="Enter Your Full Name"
                  />
                  {errors.name && (
                    <div className="text-red-500 text-sm">{errors.name}</div>
                  )}
                </div>
                <div>
                  <label className="block p-1 text-gray-700">
                    Email{" "}
                    <span className="text-red-500 font-bold text-xl">*</span>
                  </label>
                  <input
                    onFocus={() => setBlurEmail(true)}
                    onBlur={() => setBlurEmail(false)}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors({ ...errors, email: "" });
                    }}
                    type="email"
                    className={`w-full px-2 focus:outline-none outline-none py-3 border-2 border-opacity-60 transition-border duration-500 ${
                      blurEmail
                        ? "border-[#FF3E41] text-[#FF3E41]"
                        : "border-[#D0D0D0]"
                    } rounded-md `}
                    placeholder="Enter Your Email"
                  />
                  {errors.email && (
                    <div className="text-red-500 text-sm">{errors.email}</div>
                  )}
                </div>
                <div className="relative">
                  <label className="block p-1 text-gray-700">
                    Password{" "}
                    <span className="text-red-500 font-bold text-xl">*</span>
                  </label>
                  <input
                    onFocus={() => setBlurPassword(true)}
                    onBlur={() => setBlurPassword(false)}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrors({ ...errors, password: "" });
                    }}
                    type={showPassword ? "text" : "password"}
                    className={`w-full px-2 outline-none focus:outline-none py-3 border-2 border-opacity-60 border-[#D0D0D0] rounded-md transition-border duration-500 ${
                      blurPassword
                        ? "border-[#FF3E41] text-[#FF3E41]"
                        : "border-[#D0D0D0]"
                    }`}
                    placeholder="Enter Your Password"
                  />
                  {showPassword ? (
                    <LuEyeOff
                      size={20}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      className="text-[#D0D0D0] cursor-pointer absolute top-[58%] right-5"
                    />
                  ) : (
                    <LuEye
                      size={20}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      className="text-[#D0D0D0] cursor-pointer absolute top-[58%] right-5"
                    />
                  )}
                  {errors.password && (
                    <div className="text-red-500 text-sm">
                      {errors.password}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  className="w-full bg-red-500 font-bold text-white py-2 rounded hover:bg-red-600 transition duration-200"
                  disabled={loading}
                >
                  {loading ? "Registering..." : "Register"}
                </button>
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="bg-white py-2 transition-transform duration-300 rounded-md font-medium text-black lg:w-[415px] hover:scale-105 w-full m-auto lg:hidden"
                >
                  Already have an Account?{" "}
                  <span className="text-red-500">Log in</span>
                </button>
              </form>
              {/* <div className="flex w-full items-center gap-5 py-5">
                                    <div className="flex-grow border w-1/4 border-black"></div>
                                    <span className="text-black text-base">or</span>
                                    <div className="flex-grow border w-1/4 border-black"></div>
                                </div>
                                <button className="w-full text-gray-700 flex items-center justify-center">
                                    Register with Google
                                    <img src={google} alt="Google" className="h-6 w-6 mr-2" />
                                </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
