import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import LandingPage from "./pages/LandingPage";
import Beats from "./pages/Beats";
import Mixing from "./pages/Mixing";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import PrivateRoute from "./utils/PrivateRoute";
import Artworks from "./pages/Artworks";
import AboutUs from "./pages/AboutUs";
import UserDashboard from "./pages/userDashboard/UserDashboard";
import Favorite from "./pages/userDashboard/Favorite";
import AccountSetting from "./pages/userDashboard/AccountSetting";
import Dashboard from "./pages/adminpannel/Dashboard";
import BeatManagement from "./pages/adminpannel/BeatManagement";
import UserManagement from "./pages/adminpannel/UserManagement";
import Analystics from "./pages/adminpannel/Analystics";
import PurchasedHistory from "./pages/userDashboard/PurchasedHistory";
import { Cart } from "./pages/Cart";
import PaymentSuccess from "./pages/PaymentConfirm";
import { ComingSoon } from "./pages/ComingSoon";
import ForgetPassword from "./pages/ForgetPassword";
import OTPVerification from "./pages/OTPVerification ";
import ChangePassword from "./pages/ChangePassword";
import { VipCatalog } from "./pages/VipCatalog";

function App() {
  return (
    <>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path={"/login"} element={<Login />} />
            <Route path={"/signup"} element={<SignUp />} />
            <Route path={"/beats"} element={<Beats />} />
            <Route path={"/mixing-&-mastering"} element={<Mixing />} />
            <Route path={"/artworks"} element={<Artworks />} />
            <Route path={"/about"} element={<AboutUs />} />
            <Route path={"/cart"} element={<Cart />} />
            <Route path={"/marketing"} element={<ComingSoon />} />
            <Route path={"/branding"} element={<ComingSoon />} />
            <Route path={"/paymentConfirm"} element={<PaymentSuccess />} />
            <Route path={"/forgotpassword"} element={<ForgetPassword />} />
            <Route path={"/otpverification"} element={<OTPVerification />} />
            <Route path={"/changepassword"} element={<ChangePassword />} />

            <Route element={<PrivateRoute />}>
              <Route path={"/purchase-history"} element={<UserDashboard />} />
              <Route path={"/favourite"} element={<Favorite />} />
              <Route path={"/account-setting"} element={<AccountSetting />} />
              <Route path={"/user-dashboard"} element={<PurchasedHistory />} />
            </Route>
            <Route path={"/admin/dashboard"} element={<Dashboard />} />
            <Route
              path={"/admin/beats-management"}
              element={<BeatManagement />}
            />
            <Route
              path={"/admin/user-management"}
              element={<UserManagement />}
            />
            <Route path={"/admin/analytics"} element={<Analystics />} />
            <Route path={"/vip"} element={<VipCatalog />} />
          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default App;
